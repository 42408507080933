<template>
  <div id="app">
    <h1>贪吃蛇游戏</h1>
    <canvas ref="gameCanvas" width="400" height="400"></canvas>
    <div id="controls">
      <button @click="startGame">开始游戏</button>
      <button @click="stopGame">结束游戏</button>
      <p>得分: {{ score }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'snake',
  data() {
    return {
      box: 20,
      snake: [{ x: 10 * 20, y: 10 * 20 }],
      food: { x: Math.floor(Math.random() * 20) * 20, y: Math.floor(Math.random() * 20) * 20 },
      direction: "RIGHT",
      score: 0,
      game: null,
      speed: 200
    };
  },
  mounted() {
    document.addEventListener("keydown", this.changeDirection);
    this.draw();
  },
  methods: {
    draw() {
      const canvas = this.$refs.gameCanvas;
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.strokeStyle = "black";
      ctx.strokeRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = "red";
      ctx.fillRect(this.food.x, this.food.y, this.box, this.box);

      ctx.fillStyle = "green";
      this.snake.forEach(segment => {
        ctx.fillRect(segment.x, segment.y, this.box, this.box);
        ctx.strokeStyle = "black";
        ctx.strokeRect(segment.x, segment.y, this.box, this.box);
      });

      let snakeX = this.snake[0].x;
      let snakeY = this.snake[0].y;

      if (this.direction === "LEFT") snakeX -= this.box;
      if (this.direction === "RIGHT") snakeX += this.box;
      if (this.direction === "UP") snakeY -= this.box;
      if (this.direction === "DOWN") snakeY += this.box;

      if (snakeX === this.food.x && snakeY === this.food.y) {
        this.score++;
        this.food = { x: Math.floor(Math.random() * 20) * this.box, y: Math.floor(Math.random() * 20) * this.box };
        this.speed = Math.max(50, this.speed - 10);
        this.restartGame();
      } else {
        this.snake.pop();
      }

      let newHead = { x: snakeX, y: snakeY };
      if (this.collision(newHead)) {
        this.stopGame();
        return;
      }

      this.snake.unshift(newHead);
    },
    collision(head) {
      return (
          head.x < 0 || head.x >= 400 || head.y < 0 || head.y >= 400 ||
          this.snake.some(segment => head.x === segment.x && head.y === segment.y)
      );
    },
    changeDirection(event) {
      event.preventDefault();

      const key = event.keyCode;
      if (key === 37 && this.direction !== "RIGHT") this.direction = "LEFT";
      else if (key === 38 && this.direction !== "DOWN") this.direction = "UP";
      else if (key === 39 && this.direction !== "LEFT") this.direction = "RIGHT";
      else if (key === 40 && this.direction !== "UP") this.direction = "DOWN";
    },
    startGame() {
      this.stopGame();
      this.snake = [{ x: 10 * this.box, y: 10 * this.box }];
      this.direction = "RIGHT";
      this.score = 0;
      this.speed = 200;
      this.restartGame();
    },
    restartGame() {
      clearInterval(this.game);
      this.game = setInterval(() => this.draw(), this.speed);
    },
    stopGame() {
      clearInterval(this.game);
    }
  }
};
</script>

<style>
body { text-align: center; font-family: Arial, sans-serif; }
canvas { border: 2px solid black; }
#controls { margin-top: 10px; }
</style>
