import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/Home.vue';
import test1 from '../components/computer.vue';
import mouse_tracking from '../components/mouse_tracking.vue';
import MarryChristmas from "../components/MarryChristmas.vue";
import buffon from "../components/buffon.vue";
import FireBall from "../components/FireBall.vue";
import snake from "../components/snake.vue";

// =====================================================
// 运行项目用npm run serve，不要用npm run dev，这是vue2项目!
// =====================================================

// 注册 Vue Router
Vue.use(Router);

// 导出路由配置
export default new Router({
    mode: 'history', // 使用历史模式，去掉 URL 中的 #
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/computer',
            name: 'computer',
            component: test1,
        },
        {
            path: '/mouse_tracking',
            name: 'mouse_tracking',
            component: mouse_tracking,
        },
        {
            path: '/MarryChristmas',
            name: 'MarryChristmas',
            component: MarryChristmas,
        },
        {
            path: '/FireBall',
            name: 'FireBall',
            component: FireBall,
        },
        {
            path: '/buffon',
            name: 'buffon',
            component: buffon,
        },
        {
            path: '/snake',
            name: 'snake',
            component: snake,
        },
    ],
});
