<template>
  <div id="app">
    <h1>蒲丰投针模拟</h1>
    <canvas ref="canvas" width="800" height="400"></canvas>
    <div id="controls">
      <button @click="startSimulation">开始模拟</button>
      <button @click="stopSimulation">中止</button>
      <p>当前 π 值: {{ piValue }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'buffon',
  data() {
    return {
      lineSpacing: 50,
      needleLength: 40,
      totalNeedles: 0,
      crossedNeedles: 0,
      interval: null,
      piValue: "计算中..."
    };
  },
  mounted() {
    this.drawBackground();
  },
  methods: {
    drawBackground() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.strokeStyle = "black";
      for (let x = this.lineSpacing; x < canvas.width; x += this.lineSpacing) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvas.height);
        ctx.stroke();
      }
    },
    dropNeedle() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      let xCenter = Math.random() * canvas.width;
      let theta = Math.random() * Math.PI;
      let x1 = xCenter - (this.needleLength / 2) * Math.cos(theta);
      let x2 = xCenter + (this.needleLength / 2) * Math.cos(theta);
      let y1 = canvas.height / 2 - (this.needleLength / 2) * Math.sin(theta);
      let y2 = canvas.height / 2 + (this.needleLength / 2) * Math.sin(theta);

      let crossed = Math.floor(x1 / this.lineSpacing) !== Math.floor(x2 / this.lineSpacing);
      this.totalNeedles++;
      if (crossed) this.crossedNeedles++;

      ctx.strokeStyle = crossed ? "red" : "blue";
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();

      if (this.crossedNeedles > 0) {
        this.piValue = ((2 * this.needleLength * this.totalNeedles) / (this.lineSpacing * this.crossedNeedles)).toFixed(6);
      }
    },
    startSimulation() {
      this.stopSimulation();
      this.drawBackground();
      this.totalNeedles = 0;
      this.crossedNeedles = 0;
      this.piValue = "计算中...";
      this.interval = setInterval(this.dropNeedle, 100);
    },
    stopSimulation() {
      clearInterval(this.interval);
    }
  }
};
</script>

<style>
body { text-align: center; font-family: Arial, sans-serif; }
canvas { border: 1px solid black; }
#controls { margin-top: 10px; }
</style>
